import React, { useContext, useEffect, useRef, useState } from "react";
import UIkit from "uikit";
import CheckoutList from "components/cart/checkout";
import ChangeAddress from "./changeAddress";
import SiremasPointsModal from "./siremasPointsModal";
import { CheckoutContext } from "context/Checkout";

const HomeDeliveryCard = ({
  items,
  shippingRate = {},
  homeDeliveryAddresses = {},
  typeAddress,
  points,
  typeDelivery = "homeDelivery",
}) => {
  const {
    baseImg,
    handleSetPointsApplied,
    handleUnSetPointsApplied,
    pointsApplied,
  } = useContext(CheckoutContext);
  const [openModal, setOpenModal] = useState("none");
  const checkRef = useRef(null);

  const { tag, state, sector, address, address2 } = homeDeliveryAddresses;

  const handleOnChange = (e) => {
    if (e.target.checked) {
      setOpenModal("points-modal");
      UIkit.modal("#webModal-container")?.show();
    } else {
      handleUnSetPointsApplied(typeDelivery);
    }
  };

  const onSetCloseModalSiremas = (isPointsApplied, points = 0) => {
    setOpenModal("none");
    if (!isPointsApplied) {
      checkRef.current.checked = false;
    } else {
      let params = {
        type: "homeDelivery",
        points,
      };
      handleSetPointsApplied(params);
      // Set Session Storage
    }
  };

  let initialValue = 0;
  const orderAmount = items.reduce(
    (prev, current) => parseFloat(prev) + parseFloat(current.total),
    initialValue
  );

  useEffect(() => {
    if (parseInt(pointsApplied[typeDelivery]) > 0)
      checkRef.current.checked = true;
  }, [pointsApplied, typeDelivery]);

  useEffect(() => {
    if (openModal !== "none") return;
    UIkit.modal(`#webModal-container`)?.hide();
  }, [openModal]);

  if (items?.length === 0) return null;

  return (
    <>
      <div className="uk-card gi_checkout_bloque3">
        <div className="gi_leftblock">
          <p className="uk-h3">{shippingRate?.name}</p>
        </div>
        <div className="item-list-block">
          <div className="uk-child-width-1-1@m uk-grid" uk-grid="true">
            <CheckoutList items={items} baseImg={baseImg} />
            <div className="uk-width-1-3 uk-width-1-4@s gi_icon_bdelivery">
              <img
                src="/assets/global/images/img_checkout-bloque3.jpg"
                alt="Icono delivery"
              />
            </div>
            <div className="uk-width-2-3 uk-width-3-4@s gi_details_bdelivery">
              <p className="gi_explain3">{shippingRate?.description}</p>
              <p className="uk-text-bold gi_addr">
                {tag}
                <br /> {state}
                {sector}
                <br /> {address}
                <br /> {address2}{" "}
              </p>
              <ChangeAddress
                typeAddress={typeAddress}
                typeDelivery={typeDelivery}
              />{" "}
              {/* Switch  */}
              {/* <div className="gi_aplic_puntos">
                <label className="switch">
                  <input
                    ref={checkRef}
                    type="checkbox"
                    defaultChecked={false}
                    disabled={points === 0}
                    onChange={handleOnChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="toggle-legend">
                  Aplicar puntos Siremás a este pedido
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <SiremasPointsModal
        isOpen={openModal}
        onSetClose={onSetCloseModalSiremas}
        points={points}
        orderAmount={orderAmount}
      />
    </>
  );
};

export default HomeDeliveryCard;
