import React, { useState } from "react";
import ReactDOM from "react-dom";
import { OPEN_CONTAINER } from "store/product/detail/actionTypes";

const VideoModal = ({ isOpen, onSetClose, assets = [] }) => {
  const VIDEOS = assets.filter((item) => item?.media_type === "video");

  const [{ src, sourceType }, setCurrentVideo] = useState({
    src: VIDEOS[0]?.original,
    sourceType: VIDEOS[0]?.source,
  });

  if (isOpen !== "videoModal") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
        onClick={() => onSetClose(OPEN_CONTAINER, "#webModal-container")}
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">Videos</p>
        <hr />
      </div>

      <div className="uk-modal-body uk-flex carousel-container">
        <div className="carousel-video-container">
          {sourceType === "L" ? (
            <video autoPlay controls loop>
              <source src={src} />
            </video>
          ) : (
            <iframe
              src={src}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
        </div>

        <aside className="carousel-list-video-container uk-padding uk-padding-remove-vertical">
          {VIDEOS?.map((item, idx) => (
            <div key={idx}>
              <p>
                <img
                  src="/assets/example/round-play-white.png"
                  onClick={() =>
                    setCurrentVideo({
                      src: item?.original,
                      sourceType: item?.source,
                    })
                  }
                  alt="cargando..."
                />
              </p>
              <hr />
            </div>
          ))}
        </aside>
      </div>
    </div>,
    document.getElementById("webModal-container")
  );
};

export default VideoModal;
