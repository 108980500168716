import {DecimalFormat} from "helpers/functions";
import React, {useRef, useState, useContext, useEffect} from "react";
import ChangeAddress from "./changeAddress";
import UIkit from "uikit";
import SiremasPointsModal from "./siremasPointsModal";
import {CheckoutContext} from "context/Checkout";

const DeliveryExpressCard = ({
    items,
    balance,
    shippingRate = {},
    deliveryExpressAddress = {},
    locationslot,
    points,
    typeDelivery = 'supermarket'
}) => {
    const [openModal, setOpenModal] = useState("none");
    const checkRef = useRef();
    const {
      handleUnSetPointsApplied, 
      handleSetPointsApplied, 
      pointsApplied} = useContext(CheckoutContext)

    const {address_tag, sector, address} = deliveryExpressAddress;

    let initialValue = 0;
    const orderAmount = items.reduce((prev, current) => parseFloat(prev) + parseFloat(current.total), initialValue);

    const handleOnChange = (e) => {
        if (e.target.checked) {
            setOpenModal("points-modal");
            UIkit.modal("#webModal-container")?.show();
        } else {
            handleUnSetPointsApplied(typeDelivery);
        }
    }

    const onSetCloseModalSiremas = (isPointsApplied, points = 0) => {
        setOpenModal("none");
        if (!isPointsApplied) {
            checkRef.current.checked = false;
        } else {
          let params ={
            type:typeDelivery,
            points
          }
            handleSetPointsApplied(params);
            // Set Session Storage
        }
    };

    useEffect(() => {
        if (openModal !== "none") return;       
        UIkit.modal(`#webModal-container`)?.hide();
    }, [openModal]);

    useEffect(() => {
        if (parseInt(pointsApplied[typeDelivery]) > 0) 
            checkRef.current.checked = true;      
    }, [pointsApplied, typeDelivery]);

    if (items?.length === 0) return null;
  
    return (
        <>
            <div className="uk-card gi_checkout_bloque1 gi_bloquedelivery">
                <div className="gi_leftblock">
                    <p className="uk-h3">Tu compra por {shippingRate?.shipping_rate}</p>
                    <p className="tiene">
                        tiene {
                        balance?.volumen
                    }
                        {" "}
                        {
                        balance?.volumen > 1 ? "artículos" : "artículo"
                    } </p>
                </div>
                <div className="gi_rightblock">
                    <p className="uk-h3">$ {
                        DecimalFormat(orderAmount)
                    }</p>
                </div>

                <div className="uk-grid" uk-grid="true">
                    <div className="uk-width-1-3 uk-width-1-4@s gi_icon_bdelivery">
                        <img src="assets/global/images/img_checkoutexpress.jpg" alt="Icono delivery"/>
                    </div>

                    <div className="uk-width-2-3 uk-width-3-4@s gi_details_bdelivery">
                        <div className="uk-h4">Entrega: {
                            shippingRate?.shipping_rate
                        }</div>
                        <p className="gi_explain3">
                            {
                            shippingRate?.description || "Este envío llegará a tu puerta en un tiempo máximo de 40 minutos"
                        } </p>
                        <p className="uk-text-bold gi_addr">
                            {address_tag}
                            <br/> {sector}
                            <br/> {address} </p>
                        <ChangeAddress typeDelivery={typeDelivery}
                            typeAddress="DE"/>
                        <p id="timeslot-delivery-express" className="uk-text-bold timeslot-delivery-express">
                            {
                            locationslot ?? "No hay horarios disponibles para Delivery express en este momento."
                        } </p>
                        {/* Switch  */}
                        {/* <div className="gi_aplic_puntos">
                            <label className="switch">
                                <input 
                                ref={checkRef}
                                type="checkbox"
                                defaultChecked={false}
                                disabled={
                                    points === 0
                                }
                                onChange={handleOnChange}/>
                                <span className="slider round"></span>
                            </label>
                            <span className="toggle-legend">
                                Aplicar puntos Siremás a este pedido
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
            <SiremasPointsModal isOpen={openModal}
                onSetClose={onSetCloseModalSiremas}
                points={points}
                orderAmount={orderAmount}
            />
            
        </>
    );
};

export default DeliveryExpressCard;
