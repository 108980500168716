/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { DecimalFormat, Encode, MoneyFormat,  publishEvent,unsubscribeEvent } from "helpers/functions";
import { getData, postData } from "helpers/service";
import UIkit from "uikit";
import AccountContext from "context/Account";
import InputMask from "react-input-mask";
import CreditcardList from "components/cart/checkout/creditcarts";
import CreditcardAdd from "components/account/profile/creditcards/add";
import Loading from "components/share/loading/table";
import alert from "helpers/alerts";
import LoadingModal from "components/share/loading/modal";
import { useNavigate } from "react-router-dom";
import { useDgiiDocumentChecker } from "hooks/useDgiiDocumentChecker";


export default function DigitalPointCheckout(props) {
  const { getCash, setCash, setPurchaseItems, purchaseItems  } = useContext(AccountContext);
  const SIRENA_CASH_ID = process.env.REACT_APP_SIRENA_CASH_ID;
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    clearErrors
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState("none");
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [formPayment, setFormPayment] = useState({});
  const [formPaymentList, setFormPaymentList] = useState([]);
  const [balance, setBalance] = useState([]);
  const [checked, setChecked] = useState(false);
  const [fiscalInvoice, setFiscalInvoice] = useState(true);
  const [documentType, setDocumentType] = useState([]);
  const [mask, setMask] = useState("");

  let navigate = useNavigate();
  const CREDITO_FISCAL = '01'; //factura de credito fiscal
  const DNI = 'Cedula';
  let accountInvoiceType = fiscalInvoice === true ? '01' : '02';

  const document = watch('document_no','');
  const currentDocTypeName = watch('document_type', DNI);

  const 
  {
   socialName, 
   isValid, 
   documentNoSpace } = useDgiiDocumentChecker(document, accountInvoiceType, currentDocTypeName);
   setValue('business_name', socialName);
   setValue('document_short', documentNoSpace);

   useEffect(() => {
    if (isValid) clearErrors('business_name');
  }, [isValid]);

  useEffect(() => {
    setLoading(true);

    let data = getCash();

    setItems(data["details"]?.filter((x) => x.error === 0) || []);
    setTotalAmount(data["total"] || 0);
    setValue('document_type', 'Cedula')

    getData(`checkout/cash-point`, {}, (res, err) => {
      if (!err) {
        setFormPayment(res.data);
        setFormPaymentList(res.formpayment);
        setBalance(res.sirena_cash);
      }
      setLoading(false);
    });

    getData(
      "setting/account/type-document",
      { cf: Encode("01") },
      (res, err) => {
        if (!err) {
          setDocumentType(res.data);
          setMask(res.data[0].mask);
        }
      }
    );
  }, []);

//************** CRM ************//
const handlePurchaseItemsSCash = (id, items, totalAmount) => {

  let _id = items.map(_=> SIRENA_CASH_ID );
  let price = items.map((item) => parseFloat(item?.amount));
  let quantity = items.map(_=> 1);

  const lineItems = {
    _id,
    price,
    quantity};

  setPurchaseItems({
    orderId: id,
    totalValue: parseFloat(totalAmount),
    currency:'DOP',
    lineItems
  });
  };

  useEffect(() => {
    let { orderId } = purchaseItems;
    if (!orderId) return;
    navigate(`/order-confirmation-cash/${Encode(orderId)}`, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setCash({details:[],total:0})
    }
  }, [purchaseItems]);


  useEffect(() => {
    if (items.length === 0) return;
    if (loading) return;
    const lineItems = {
      _id: items?.map((_) => SIRENA_CASH_ID),
      price: items?.map((item) => parseFloat(item?.amount)),
      quantity: items?.map((_) => 1),
      currency: "DOP",
    };

    publishEvent("view-cart", lineItems, "checkout-box");
    
    return () => {
      unsubscribeEvent("view-cart", null, "checkout-box");
    };
  }, [items, loading]);

//************* FIN CRM ***********//
  
  const onSubmit = (data) => {
    if (formPayment?.fp_format === "CC" && formPayment?.ccId === null) {
      alert.confirm(
        ` <p>Se requiere una tarjeta de crédito o débito registrada.</p>`,
        "warning"
      );
      return;
    } else {
      let submitOrder = {
        details: items.filter((x) => x.error === 0),
        total: totalAmount,
        recurrence: checked ? "NONE" : data["recurrence"],
        invoice: {
          dgii_cf_id: fiscalInvoice ? "01" : "02",
          document_type_id: data["document_type_id"],
          document_no: data["document_no"],
          business_name:  socialName,
        },
        cc: {
          cvv: Encode(data["CVV"]),
          confirm: Encode(data["confirm_amount"]),
          id: formPayment?.ccId,
        },
        formpaymentid: formPayment?.formpaymentId,
        formpayment: formPayment?.fp_format,
        comment: data["comment"],
      };

      setOpen("loading");
      UIkit.modal("#webModal").show();

      postData(`checkout/cash-point`, submitOrder, {}, (res, err) => {
        if (!err) {
          handlePurchaseItemsSCash(res.id, items, totalAmount);
          onSetClose("none");
          // Go to order confirmation
        } else {
          setOpen("none");
          UIkit.modal(`#webModal`).hide();
          setLoading(false);
        }
      });
    }
  };

  const ToPreviousStep = () => {
    props.previousStep();
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const onChangeDocumentType = (e) => {
    let document_type = documentType.find(
      (element) => element.value === e.target.value
    );
    setValue('document_type', document_type?.label)
    setMask(document_type.mask);
  };

  const onSetClose = (action = `none`) => {
    setOpen(action);

    if (action == "none") {
      UIkit.modal(`#webModal`).hide();
    }
  };

  const onSetCreditCard = (reload = false, message = "", data = null) => {
    if (data !== null) {
      let xyz = {
        ...formPayment,
        ccId: data.ccId,
        cc_exp: data.cc_exp,
        cc_mask: data.cc_mask,
        cc_type: data.cc_type,
        confirmed: data.confirmed,
      };

      setFormPayment(xyz);
    }

    onSetClose();
  };

  return (
    <div className="uk-section" id="section-main">
      <div className="uk-container uk-flex uk-flex-center">
        <div className="uk-width-1-1 uk-width-4-5@m">
          <h2 className="uk-h2 uk-text-center">Sirena Cash</h2>
          {loading ? (
            <Loading />
          ) : (
            <div
              id="checkout-box"
              className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small uk-light"
            >
              <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
                <div className="title-border">
                  <p className="uk-h4">Pagar tu Compra:</p>
                </div>
                <div className="uk-grid uk-grid-medium" uk-grid="true">
                  <div className="uk-width-1-1 uk-width-3-5@m">
                    <p className="uk-hidden">
                      <label>
                        <input
                          name="by_default"
                          checked={checked}
                          onChange={(e) => {
                            setChecked(!checked);
                          }}
                          className="uk-checkbox"
                          type="checkbox"
                        />
                        <small style={{ marginTop: "4px" }}>
                          Agregar Notificacion de Recurrencia
                        </small>
                      </label>
                    </p>
                    {checked && (
                      <p>
                        <div className="uk-margin-small">
                          <label className="uk-form-label" htmlFor="dgii_cf_id">
                            Recurrencia
                            <span className="required">*</span>
                          </label>
                          <div className="uk-form-controls">
                            <select
                              className="uk-select"
                              name="recurrence"
                              {...register("recurrence")}
                            >
                              <option value="DAILY">Diaria</option>
                              <option value="WEEKLY">Semanal</option>
                              <option value="BIWEEKLY">Quincenal</option>
                              <option value="MONTHLY">Mensual</option>
                              <option value="BIMONTHLY">Bimestral</option>
                              <option value="TRIMONTHLY">Trimestral</option>
                              <option value="SIXMONTHLY">Semestral</option>
                              <option value="YEARLY">Anual</option>
                            </select>
                          </div>
                        </div>
                      </p>
                    )}

                    <table className="uk-table uk-table-divider uk-table-small uk-table-hover">
                      <thead>
                        <tr>
                          <th className="uk-width-auto">Persona</th>
                          <th className="uk-width-auto">Contacto</th>
                          <th className="uk-width-auto uk-text-right">Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.document} <br />
                              {`${item.first_name} ${item.last_name}`}
                            </td>
                            <td>
                              {item.email} <br />
                              {item.phone}
                            </td>
                            <td className="uk-width-auto uk-text-right">
                              {MoneyFormat(item.amount)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="uk-width-1-1 uk-width-2-5@m">
                    <div
                      className="item-list-footer"                      
                    >
                      <div className="item-list-block">
                        {formPaymentList.length > 0 && (
                          <select
                            className="uk-select"
                            onChange={(e) => {
                              var index = e.nativeEvent.target.selectedIndex;
                              setFormPayment({
                                ...formPayment,
                                formpaymentId: e.target.value,
                                formpayment: formPaymentList[index].formpayment,
                                fp_format: formPaymentList[index].format,
                              });
                            }}
                          >
                            {formPaymentList?.map((item, i) => (
                              <option key={i} value={item.id}>
                                Pagar con: {item.formpayment}
                              </option>
                            ))}
                          </select>
                        )}

                        <p>
                          Pagar: <strong>{formPayment?.formpayment}</strong>{" "}
                          {formPayment?.ccId !== null && (
                            <>
                              (
                              <a
                                href="#"
                                className="uk-button uk-button-link"
                                onClick={() => setOpen("credit-cards")}
                                uk-toggle="target: #webModal"
                              >
                                cambiar
                              </a>
                              )
                            </>
                          )}
                        </p>

                        {formPayment?.fp_format === "cc" &&
                          (formPayment?.ccId !== null ? (
                            <div>
                              <div className="uk-flex uk-grid-small">
                                <div className="uk-width-auto">
                                  <img
                                    src={`/assets/creditcart/${formPayment?.cc_type}.jpg`}
                                    alt={formPayment?.cc_type}
                                    style={{ maxHeight: 35 }}
                                  />
                                </div>
                                <div className="uk-width-expand">
                                  <strong>{formPayment?.cc_mask}</strong>
                                  <br />
                                  <small>Vence: {formPayment?.cc_exp}</small>
                                </div>
                                <div className="uk-width-auto">
                                  <input
                                    type="password"
                                    name="CVV"
                                    autoComplete="cc-csc"
                                    maxLength="4"
                                    className="uk-input uk-form-width-small"
                                    placeholder="CVV"
                                    {...register("CVV", {
                                      required: "CVV es requerido",
                                    })}
                                  />
                                  <p className="uk-width-1-1 error">
                                    {errors?.CVV?.message}
                                  </p>
                                </div>
                              </div>
                              {formPayment?.confirmed === 0 ? (
                                <div className="uk-grid-small">
                                  <div className="uk-width-1-1">
                                    <br />
                                    <p className="uk-h5">
                                      Confirmar tarjeta de crédito
                                    </p>
                                  </div>
                                  <div className="uk-width-1-1 uk-margin-remove">
                                    <div className="uk-margin-small">
                                      <label
                                        className="uk-form-label"
                                        htmlFor="confirm_amount"
                                      >
                                        Monto transacción{" "}
                                        <span className="required">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <input
                                          className="uk-input uk-text-right"
                                          name="confirm_amount"
                                          {...register("confirm_amount", {
                                            required:
                                              "Monto transacción es requeridos",
                                          })}
                                          type="text"
                                          placeholder="0.00"
                                        />
                                      </div>
                                      <p className="error">
                                        {errors?.confirm_amount?.message}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-1 uk-margin-remove">
                                    Se realizó un cargo a su tarjeta de 1 a 20
                                    pesos que será su clave de validación.
                                    Consulte dicho monto en su tarjeta. Este
                                    proceso solo es necesario al registrar por
                                    primera vez la tarjeta y el monto será
                                    reversado.
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div>
                              <p className="uk-text-bold">
                                No tienes una tarjeta de crédito o débito
                                registrada.
                              </p>
                              <p>
                                <a
                                  id="btn-register-creditcard"
                                  onClick={() => setOpen("CreditcardAdd")}
                                  className="uk-button uk-button-default uk-button-small"
                                  uk-toggle="target: #webModal"
                                >
                                  <span>Registrar</span>
                                  <i className="far fa-wallet"></i>
                                </a>
                              </p>
                            </div>
                          ))}

                        {formPayment?.fp_format === "credit" && (
                          <div>
                            <hr />
                            <p>Balance en Credito</p>
                            <p>
                              <span>
                                RD${" "}
                                <strong>
                                  {DecimalFormat(balance.credito)}
                                </strong>
                              </span>
                            </p>
                            {balance?.orders > 0 && (
                              <>
                                <p>Balance en pedidos pendientes procesar</p>
                                <p>
                                  <span>
                                    RD${" "}
                                    <strong>
                                      {DecimalFormat(balance.orders_amount)}
                                    </strong>
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>

                      <hr className="uk-margin-medium-top" />

                      <textarea
                        name="comment"
                        cols="30"
                        rows="3"
                        className="uk-textarea"
                        {...register("comment")}
                        placeholder="Comentario (opcional)"
                      ></textarea>

                      <hr className="uk-margin-medium-top" />
                      <p>
                        <label>
                          <input
                            name="by_default"
                            checked={fiscalInvoice}
                            onChange={(e) => {
                              setFiscalInvoice(!fiscalInvoice);
                            }}
                            className="uk-checkbox"
                            type="checkbox"
                          />
                          <span style={{ marginTop: "4px" }}>
                            <small>Quiero factura de Crédito Fiscal</small>
                          </span>
                        </label>
                      </p>
                      {fiscalInvoice && (
                        <>
                          <div className="uk-margin-small">
                            <div className="uk-grid-small uk-child-width-1-2 uk-grid">
                              <label>
                                <select
                                  className="uk-select"
                                  name="document_type_id"
                                  {...register("document_type_id", {
                                    required: "Tipo de documento es requerido",
                                  })}
                                  onChange={onChangeDocumentType}
                                >
                                  {documentType.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item.value}
                                    >{`${item.label}`}
                                    </option>
                                  ))}
                                </select>
                                <p className="error">
                                  {errors?.document_type_id?.message}
                                </p>
                              </label>
                              <label>
                                <Controller
                                  name="document_no"
                                  control={control}
                                  rules={{
                                    required: "El No. documento es requerido",
                                  }}
                                  render={({ field }) => (
                                    <InputMask
                                      name="document_no"
                                      alwaysShowMask
                                      mask={mask}
                                      {...field}
                                      className="uk-input"
                                    />
                                  )}
                                />
                                <p className="error">
                                  {errors?.document_no?.message}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div className="uk-margin-small">
                            <input
                              maxLength="128"
                              className="uk-input"
                              type="text"
                              name="business_name"
                              style={{pointerEvents:'none'}}
                              placeholder="Nombre / Razón social*"
                              {...register("business_name", {
                                validate:  accountInvoiceType === CREDITO_FISCAL 
                                  ? ()=>( isValid || "Nombre/Razón social no fue encontrado.")
                                  : null                               
                              })}
                            />
                            <p className="error">
                              {errors?.business_name?.message}
                            </p>
                          </div>
                        </>
                      )}
                      <hr className="uk-margin-medium-top" />
                      <div className="item-list-total">
                        <div>
                          <div className="uk-flex uk-grid-small">
                            <div className="uk-width-1-3 uk-text-uppercase">
                              Descuento:
                            </div>
                            <div className="uk-width-2-3 uk-text-right">
                              RD$ 0.00
                            </div>
                          </div>
                          <div className="uk-flex uk-grid-small">
                            <div className="uk-width-1-3 uk-text-uppercase">
                              Itbis:
                            </div>
                            <div className="uk-width-2-3 uk-text-right">
                              RD$ 0.00
                            </div>
                          </div>
                          <div className="uk-flex uk-grid-small uk-text-bolder">
                            <div className="uk-width-1-3 uk-text-uppercase">
                              Total:
                            </div>
                            <div className="uk-width-2-3 uk-text-right">
                              RD$ {MoneyFormat(totalAmount)}
                            </div>
                          </div>
                          <div className="uk-flex uk-grid-small uk-text-bolder uk-text-secondary">
                            <div className="uk-width-1-3 uk-text-uppercase">
                              A Pagar:
                            </div>
                            <div className="uk-width-2-3 uk-text-right">
                              RD$ {MoneyFormat(totalAmount)}
                            </div>
                          </div>
                        </div>

                        <div
                          className="uk-flex uk-flex-wrap uk-flex-center uk-grid-small item-list-btns"
                          uk-grid="true"
                        >
                          <div className="uk-width-1-1 uk-text-center">
                            Al realizar tu pedido, aceptas los{" "}
                            <a
                              href="/site/page/terminos-y-condiciones"
                              target="_blank"
                            >
                              términos y condiciones
                            </a>{" "}
                            de Sirena.do
                          </div>
                        </div>

                        <div className="uk-margin uk-text-center">
                          <button
                            id="btn-go-back-to-cart"
                            type="button"
                            className="uk-button  uk-button-custom"
                            onClick={ToPreviousStep}
                          >
                            Anterior
                          </button>
                          <button
                            id="btn-pay"
                            className="uk-button uk-button-default uk-button-custom"
                            onClick={handleSubmit(onSubmit)}
                          >
                            Pagar compra
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <CreditcardList
        isOpen={open}
        onSetClose={onSetClose}
        onSetCreditCard={onSetCreditCard}
      />
      <CreditcardAdd
        isOpen={open === "CreditcardAdd" ? "add" : "none"}
        setClose={onSetCreditCard}
      />
      <LoadingModal
        isOpen={open}
        message="Estamos procesando el pago de su pedido, por favor espere..."
      />
    </div>
  );
}
